/*
 * @Author: inwen6
 * @Date: 2020-12-17 09:47:35
 * @LastEditTime: 2020-12-18 15:54:54
 * @LastEditors: your name
 * @Description: api列表
 * @FilePath: /hheiqngting-gw/src/api/api_list.js
 */
 import axios from './request.js'
//banner
 export function banner(params){
     return axios({
         url:"/ow/banner/findIsShow",
         method:"get",
         params
     })
 }
 //产品列表
 export function product(params){
     return axios({
         url:"/ow/product/page",
         method:"get",
         params
     })
 }
 //成功案例
 export function successfulCases(params){
     return axios({
         url:"/ow/successfulCases/page",
         method:"get",
         params
     })
 }
 //新闻资讯
 export function news(params){
     return axios({
         url:"/ow/news/page",
         method:"get",
         params
     })
 }
 //新闻资讯详情
 export function newsDetail(params){
     return axios({
         url:"/ow/news/query",
         method:"get",
         params
     })
 }
 //加盟合作
 export function joinApply(data){
     return axios({
         url:"/ow/joinApply/add",
         method:"post",
         data
     })
 }
