/*
 * @Author: inwen6
 * @Date: 2020-12-17 09:32:04
 * @LastEditTime: 2021-04-02 23:16:55
 * @LastEditors: your name
 * @Description: 请求封装
 * @FilePath: /hheiqngting-gw/src/api/request.js
 */
import axios from 'axios'
// import {getToken} from './auth'

axios.defaults.headers["Content-Type"] = 'application/json;charset=utf-8'
const service = axios.create({
    baseURL:'https://applet.heiqingting.net',
    // baseURL: '127.0.0.1',
    timeout:10000
})

// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    // const isToken = (config.headers || {}).isToken === false
    // if (getToken() && !isToken) {
    //   config.headers['Authorization'] =getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    // }
    return config
  }, error => {
      console.log(error)
      Promise.reject(error)
  })

// 响应拦截器
service.interceptors.response.use(res => {
      return res.data
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    return Promise.reject(error)
  }
)

export default service
